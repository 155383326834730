<template>
    <div class="z-10 flex flex-col items-center gap-5">
        <div v-motion-slide-visible-once-bottom :delay="1000" class="flex flex-col justify-center items-center lg:px-[10vw] p-3 gap-5">
            <!-- <h3 class="text-2xl font-bold text-primary">Features</h3> -->
            <h2 class="text-3xl md:text-5xl font-extrabold text-center max-w-[550px]">Transform the way you <span class="text-primary">manage</span> legal matters</h2>
            <p class="opacity-60 text-center font-semibold max-w-[500px] text-sm lg:text-base">Powered by cutting-edge technology. It sets a new standard for efficiency and effectiveness in legal practice.</p>
        </div>
        <div  id="features" class="gap-28 mt-16 flex flex-col md:gap-40 w-full">
            <FeatureBlock :isRight="true" whiteBgDirection="toright" title="Calendar and Scheduling" content="Easily organize and track all your cases in one place. Assign tasks, set deadlines, and collaborate with your team seamlessly.">
                <template #right>
                    <!-- <CalendarScheduling v-if="desktopMode" v-motion-slide-visible-once-left /> -->
                    <CalendarScheduling />
                </template>
            </FeatureBlock>

            <FeatureBlock :isRight="false" title="Case Management" content="Keep detailed records of client information, including contact details, case history, and billing information.">
                <template #left>
                    <!-- <Case v-if="desktopMode" v-motion-slide-visible-once-right/> -->
                    <Case />
                </template>
            </FeatureBlock>

            <FeatureBlock :isRight="true" whiteBgDirection="toleft" title="Employee Management" content="Efficiently manage staff information, fostering seamless collaboration and streamlined task allocation within your team.">
                <template #right>
                    <!-- <Employee v-if="desktopMode" v-motion-slide-visible-once-left /> -->
                    <Employee />
                </template>
            </FeatureBlock>

            <FeatureBlock :isRight="false" title="Client Management" content="Easily organize and access client data, enhancing communication and fostering strong client relationships.">
                <template #left>
                    <!-- <Client v-if="desktopMode" v-motion-slide-visible-once-right /> -->
                    <Client />
                </template>
            </FeatureBlock>

            <FeatureBlock :isRight="true" whiteBgDirection="toright" title="User Access and Permission" content="Ensure data security with customizable user access levels, granting appropriate permissions for different roles within the system.">
                <template #right>
                    <!-- <Users v-if="desktopMode" v-motion-slide-visible-once-left /> -->
                    <Users />
                </template>
            </FeatureBlock>
        </div>
    </div>
</template>

<script setup>
import FeatureBlock from './FeatureBlock.vue'
import CalendarScheduling from './calendar/CalendarScheduling.vue'
import Case from './case/Case.vue';
import Employee from './employee/Employee.vue'
import Client from './client/Client.vue';
import Users from './users/Users.vue';
import { useGetDeviceType } from '@/composables/useGetDeviceType.js'

const { desktopMode } = useGetDeviceType()

</script>

<style>

</style>