<template>
    <div :delay="1000" class="absolute -left-5 top-[30%] flex flex-col gap-3 justify-center items-start lg:gap-5 ml-3 lg:ml-0">
        <div class="highlight duration-1000 flex flex-nowrap justify-center items-center bg-primary text-white px-3 py-2 lg:px-5 lg:py-3 rounded-lg gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24"><path fill="currentColor" d="M18 12.998h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 0 1 0-2h5v-5a1 1 0 0 1 2 0v5h5a1 1 0 0 1 0 2"/></svg>
            Add New Event
        </div>

        <div class="highlight duration-1000 flex flex-nowrap justify-center items-center bg-primary text-white px-3 py-2 lg:px-5 lg:py-3 rounded-lg gap-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 16 16"><g fill="currentColor"><path d="M12.5 16a3.5 3.5 0 1 0 0-7a3.5 3.5 0 0 0 0 7m.354-5.854l1.5 1.5a.5.5 0 0 1-.708.708L13 11.707V14.5a.5.5 0 0 1-1 0v-2.793l-.646.647a.5.5 0 0 1-.708-.708l1.5-1.5a.5.5 0 0 1 .708 0M11 5a3 3 0 1 1-6 0a3 3 0 0 1 6 0"/><path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/></g></svg>            
            DAR
            <svg xmlns="http://www.w3.org/2000/svg" width="0.5rem" height="0.5rem" viewBox="0 0 15 15"><path fill="currentColor" d="M7.5 12L0 4h15z"/></svg>
        </div>

        <div class="highlight duration-1000 flex flex-nowrap justify-center items-center bg-primary text-white px-3 py-2 lg:px-5 lg:py-3 rounded-lg gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24"><path fill="currentColor" d="M19 19H5V8h14m-3-7v2H8V1H6v2H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-1V1m-1 11h-5v5h5z"/></svg>
            Today's Event
        </div>
    </div>
</template>

<script>

</script>

<style>

</style>