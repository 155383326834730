<template>
  <!-- <div class="overflow-hidden w-full h-screen preloader flex flex-col items-center justify-center gap-3 pb-20 md:pb-10">
    <div class="min-h-10 w-fit flex justify-center relative">
      <img  loading="eager" class="logo-anim-main z-10" src="./../../assets/LOGO/LOGO.png" alt="">
      <div class="loader-whitebg rounded-[50%]"></div>
    </div>
    <span class="text-4xl textlogo-anim-main font-extrabold lg:text-5xl z-10">LAWSYS</span>
  </div> -->
  <div class="w-screen h-screen bg-white flex items-center justify-center">
    <img loading="eager" draggable="false" class="w-full max-w-[700px] aspect-video object-contain object-center" :src="imageUrl" alt="">
</div>

</template>

<script setup>
import { ref, onMounted } from "vue";

const imageUrl = ref(require("@/assets/v2/loader.gif") + `?random=${Math.random()}`);



</script>



<style>

</style>