<template>
  <Preloader v-if="isLoading" />
  <div v-else :class="{
    'app-filter' : filterOn,
    }" class="flex flex-col items-end">
    <Navigation @toggleFilter="addBlackFilter"/>
    <router-view />
  </div>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import LandingPage from "./views/landingpage/LandingPage.vue";
import Preloader from "./views/preloader/Preloader.vue";
import { onMounted, ref } from "vue";

export default {
  setup() {
    const desktopMode = ref(window.innerWidth >= 1024)
    const filterOn = ref(false)
    const isLoading = ref(true)

    const addBlackFilter = () =>{
      filterOn.value = !filterOn.value
    }

    window.addEventListener("resize", function () {
      desktopMode.value = window.innerWidth >= 1024;
      if(desktopMode.value) filterOn.value = false
    });

    const handleLoad = () => {
      setTimeout(()=>{
        isLoading.value = false;
      },4000)

      // isLoading.value = false;
    };

    onMounted(() => {
      window.addEventListener('load', handleLoad);
    });


    return {
      filterOn,
      addBlackFilter,
      isLoading
    }
  },
  components: {
    Navigation,
    LandingPage,
    Preloader,
  },
};
</script>

<style>
#app {
  /* position: relative; */
}
</style>
