<template>
    <div class="min-w-[280px] sm:min-w-[500px] group select-none case-parent relative bg-gradient-to-b from-primary from-50% via-[#f6cfd5] via-70% to-bgcolor to-100% p-[2px] pb-0 rounded-t-lg md:rounded-t-3xl">
        <div class="sm:text-sm text-xs bg-gradient-to-b from-bgcolor from-50% via-white via-70% to-white/50 to-100% rounded-t-lg py-3 flex flex-col md:rounded-t-3xl">
            <div class="font-bold text-primary py-3 mx-5 border-b border-neutral-400 sm:text-lg">
                Users
            </div>

            <div class="font-bold text-primary py-3 mx-5 border-b border-neutral-400 sm:text-lg">
                Profile
            </div>

            <UserRow main="System Admin" sub="admin-sven" acro="SA" :highlight="true" />

            <UserRow main="Aivan Vitalista" sub="aivan.vitalista" acro="AFV" />
            <UserRow main="Ayaka Kamisato" sub="ayaka.kamisato" acro="AK" />
            <UserRow main="Alyssa Del Rosario" sub="alyssa.delrosario" acro="ADR" />
            <UserRow main="Nina Velasco" sub="nina.velasco" acro="NV" :last="true" />
        </div>

        <RoleGroup v-if="desktopMode" v-motion-slide-visible-once-right :delay="1000"/>
        <RoleGroup v-else />
        </div>
</template>

<script setup>
import { useGetDeviceType } from "@/composables/useGetDeviceType";

import RoleGroup from "./RoleGroup.vue";
import UserRow from "./UserRow.vue";

const { desktopMode } = useGetDeviceType()

</script>

<style>

</style>