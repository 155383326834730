<template>
  <div
    ref="component"
    id="mobility"
    class="relative w-full flex flex-col gap-5 items-center lg:flex-row z-40"
  >
    <img
      draggable="false"
      class="absolute w-full h-1/2 z-0 top-0 md:h-[150%] lg:h-[110%] lg:-top-10 md:-top-40"
      src="./../../assets/v2/mobility_WARP.png"
      alt=""
    />

    <div
      class="w-full flex flex-col-reverse md:flex-row gap-5 lg:gap-44 p-3 items-center lg:flex-row lg:px-[10vw] lg:justify-around z-40"
    >
      <div
        class="text-neutral-700 md:text-white flex flex-col gap-8 overflow-hidden items-center lg:items-start"
      >
        <h2 class="font-extrabold md:text-4xl text-3xl text-left max-w-[500px]">
          Mobile accessibility across all devices—from your desk to your
          <span class="text-primary md:text-white">pocket</span>
        </h2>
        <p
          class="sm:text-pretty md:max-w-[450px] font-medium text-lg md:text-xl text-left"
        >
          Stay connected and manage your law office effortlessly, anytime,
          anywhere, straight from your phone.
        </p>
        <!-- <router-link
          to="/getlawsys"
          class="md:bg-white md:text-primary bg-primary text-white group flex flex-row flex-nowrap justify-center items-center gap-2 w-full max-w-64 xl:w-1/2 hover:bg-secondary md:hover:bg-neutral-100 duration-200 font-bold text-xl rounded-full px-4 py-3"
        >
          Get LawSys Mobile
          <svg
            class="group-hover:translate-x-3 duration-200"
            xmlns="http://www.w3.org/2000/svg"
            width="1.5rem"
            height="1.5rem"
            viewBox="0 0 48 48"
          >
            <path
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
              d="m19 12l12 12l-12 12"
            />
          </svg>
        </router-link> -->
        <div class="w-full justify-center md:justify-start flex flex-wrap gap-2">
          <a href="https://play.google.com/store/apps?hl=en&gl=US&pli=1" target="_blank">
            <img src="./../../assets/v2/googleplay.png" alt="">
          </a>

          <a href="https://apps.apple.com/us/app/lawsys/id6452399446" target="_blank">
            <img src="./../../assets/v2/appstore.png" alt="">
          </a>
        </div>
      </div>
      <img
        v-if="desktopMode"
        :class="{ 'mobility-phone': inViewport, 'hide-phone': !inViewport }"
        draggable="false"
        class="translate-x-[100vw] w-full max-w-96 lg:max-w-none lg:w-[35%]"
        src="./../../assets/v2/Phone_animation.png"
        alt="mobility_phone"
      />

      <img
        v-else
        draggable="false"
        class="w-full max-w-96 lg:max-w-none lg:w-[35%]"
        src="./../../assets/v2/Phone_animation.png"
        alt="mobility_phone"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import { useGetDeviceType } from "@/composables/useGetDeviceType";

const { desktopMode } = useGetDeviceType();
const component = ref(null);
const inViewport = ref(false);

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        inViewport.value = true;
      } else {
        inViewport.value = false;
      }
    });
  },
  {
    rootMargin: "0px 0px -30% 0px",
  }
);

// Lifecycle hooks
onMounted(() => {
  try {
    observer.observe(component.value);
  } catch (error) {}
});

onUnmounted(() => {
  try {
    observer.unobserve(component.value);
  } catch (error) {}
});
</script>

<style></style>
