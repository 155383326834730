<template>
  <div id="overview"
    class="relative items-center pt-20 md:pt-48 flex w-full min-h-screen justify-center mx-auto p-5 pb-0 flex-col gap-10 lg:px-[10vw] lg:gap-0"
  >
    <TextContainer 
      v-if="desktopMode"
      v-motion
      :initial="{ opacity: 0, x: -100 }"
      :enter="{ opacity: 1, x: 0 }"
      :delay="200"/>

    <TextContainer 
      v-else
    />

    <HeroContainer 
    />

    <img draggable="false" class="w-full hidden absolute right-0 top-0 z-[-1] lg:block" src="@/assets/v2/hero/WARP.png" alt="">

    
    <img draggable="false" class="w-full h-1/2 absolute right-0 z-[-1] bottom-10 lg:hidden" src="@/assets/v2/hero/WARP2.png" alt="">

  
  </div>
</template>

<script setup>
import TextContainer from "./TextContainer.vue";
import HeroContainer from "./HeroContainer.vue";
import { useGetDeviceType } from "@/composables/useGetDeviceType";

const { desktopMode } = useGetDeviceType()
</script>

<style></style>
