<template>
  <div class="w-[11.5rem] sm:w-52 absolute top-5 -right-3 sm:-right-14 flex flex-col gap-32">
        <div class="scale-90 md:scale-100 md:group-hover:scale-90 duration-1000 sm:text-sm w-full text-xs bg-primary text-white flex flex-nowrap justify-center items-center p-3 rounded-lg gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24"><path fill="currentColor" d="M18 12.998h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 0 1 0-2h5v-5a1 1 0 0 1 2 0v5h5a1 1 0 0 1 0 2"/></svg>
            Register an Employee
        </div>

        <div class="w-full flex flex-col gap-2">
            <div class="bg-primary text-white flex flex-nowrap justify-between items-center px-3 py-2 rounded-lg gap-2">
                <div class="text-neutral-700 font-bold text-xs border-2 border-secondary bg-white w-10 h-10 flex items-center justify-center rounded-full">AVF</div>
                <div class="text-white flex flex-col">
                    <span class="font-semibold text-xs sm:text-sm">Aivan Vitalista</span>
                    <span class="text-[8px] sm:text-[10px]">SENIOR_ASSOCIATE</span>
                </div>
                <svg class="sm:w-[1.5rem] sm:h-[1.5rem]" xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 24 24"><path fill="currentColor" d="m18.988 2.012l3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287l-3-3L8 13z"/><path fill="currentColor" d="M19 19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2z"/></svg>
            </div>

            <div class="bg-[#c6c7c8] text-white px-3 py-2 rounded-lg gap-2 text-xs sm:text-sm">
                Personal Information
            </div>

            <div class="bg-[#c6c7c8] text-white px-3 py-2 rounded-lg gap-2 text-xs sm:text-sm">
                Work Information
            </div>

            <div class="bg-[#c6c7c8] text-white px-3 py-2 rounded-lg gap-2 text-xs sm:text-sm">
                Credentials
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>