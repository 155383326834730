<template>
  <div
 class="w-full text-center py-5 flex flex-col items-center item gap-4 lg:max-w-[800px] lg:gap-6 items-left">
    <h1 class="w-full text-4xl font-extrabold lg:text-5xl xl:text-6xl text-center">
      Embrace the Future with <span class="text-primary">LawSys</span>
    </h1>
    <p class="opacity-60 text-sm font-semibold lg:w-4/5 lg:text-base max-w-[600px] mx-auto lg:mx-0 xl:text-lg text-balance">
      Empower your firm with seamless case management, document handling, firm operations, and client interactions across all devices.
    </p>
    <div
      class="flex justify-center flex-nowrap lg:flex-wrap w-full items-center text-white gap-2 sm:gap-5"
    >
      <router-link to="/getlawsys"
        class="group flex flex-row flex-nowrap justify-center items-center gap-2 w-full max-w-64 lg:max-w-56 bg-primary hover:bg-secondary duration-200 font-bold text-sm lg:text-lg rounded-full py-3 px-5"
      >
        Request a Demo
        <svg class="group-hover:translate-x-3 duration-200" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m19 12l12 12l-12 12"/></svg>
      </router-link>
      <!-- <router-link to="/demo"
        class="group w-1/2 flex flex-row flex-nowrap justify-center items-center gap-2 duration-200 max-w-64 lg:max-w-56 font-bold text-xs md:text-sm lg:text-lg p-3 rounded-full bg-transparent text-neutral-700"
      >
        Get Demo
        <svg class="group-hover:translate-x-3 duration-200" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m19 12l12 12l-12 12"/></svg>
      </router-link> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
