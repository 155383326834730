<template>
    <Form>
        <template #text>
            <h2 class="text-xl font-bold lg:text-4xl">Get started today</h2>
            <p class="text-sm text-pretty lg:max-w-96 lg:text-base">Kickstart your journey towards a more organized and efficient legal practice.</p>
            <p class="text-sm text-pretty lg:max-w-96 lg:text-base">Your email will be your gateway to accessing LawSys.</p>
        </template>
        <template #form>
            <form class="lg:w-1/2 lg:h-screen lg:justify-center lg:items-start lg:mr-20  w-full max-w-[32rem] flex flex-col items-center justify-start px-5 gap-7" action="" @submit.prevent="submitData">
            <div class="w-full">
                <div class="group hover:border-neutral-800 lg:p-3 focus-within:border-neutral-800 duration-200 bg-bgcolor w-full relative border-2 p-2  border-neutral-400 rounded-xl flex justify-start items-center">
                    <input @input="() => validateField('name')" v-model.trim="name" id="name" class="text-sm peer focus:outline-none w-full bg-bgcolor" type="text" required>
                    <label class="group-hover:text-neutral-800 hover:cursor-text peer-focus:-translate-y-6 peer-focus:text-neutral-800 peer-valid:-translate-y-6 peer-focus:bg-bgcolor peer-valid:bg-bgcolor p-1 duration-100 absolute text-neutral-400 text-sm font-medium lg:text-base" for="name">Full Name</label>
                </div>
                <div ref="nameWarning" class="text-red-400 font-semibold mx-auto  text-xs lg:text-sm">
                    
                </div>
            </div>

            <div class="w-full">
                <div class="group hover:border-neutral-800 lg:p-3 focus-within:border-neutral-800 duration-200 bg-bgcolor w-full relative border-2 p-2 border-neutral-400 rounded-xl flex justify-start items-center">
                    <input @input="() => validateField('email')" v-model.trim="email" id="email" class="text-sm peer focus:outline-none w-full bg-bgcolor" type="text" required>
                    <label class="group-hover:text-neutral-800 hover:cursor-text peer-focus:-translate-y-6 peer-focus:text-neutral-800 peer-valid:-translate-y-6 peer-focus:bg-bgcolor peer-valid:bg-bgcolor peer-invalid:peer-blur:text-red-400 p-1 duration-100 absolute  text-neutral-400 text-sm font-medium lg:text-base" for="email">Work Email</label>
                </div>
                <div ref="emailWarning" class="text-red-400 font-semibold mx-auto  text-xs lg:text-sm">
                    
                </div>
            </div>

            <div class="w-full">
                <div class="group hover:border-neutral-800 lg:p-3 focus-within:border-neutral-800 duration-200 bg-bgcolor w-full relative border-2 p-2 border-neutral-400 rounded-xl flex justify-start items-center">
                    <input @input="() => validateField('contact')" v-model.trim="contact" id="contact" class="text-sm peer focus:outline-none w-full bg-bgcolor" type="text" required>
                    <label class="group-hover:text-neutral-800 hover:cursor-text peer-focus:-translate-y-6 peer-focus:text-neutral-800 peer-valid:-translate-y-6 peer-focus:bg-bgcolor peer-valid:bg-bgcolor p-1 duration-100 absolute  text-neutral-400 text-sm font-medium lg:text-base" for="contact">Contact Number</label>
                </div>
                <div ref="contactWarning" class="text-red-400 font-semibold mx-auto  text-xs lg:text-sm">
                    
                </div>
            </div>

            <button :disabled="submitDisabled" class="disabled:bg-neutral-300 mr-auto lg:px-5 px-3 py-2 group flex flex-row flex-nowrap justify-center items-center gap-2 hover:bg-secondary duration-200 font-bold text-sm text-white bg-primary text-center rounded-full">
                Send Request
                <svg :class="{'group-hover:translate-x-3': !submitDisabled}" class="duration-200" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m19 12l12 12l-12 12"/></svg>
            </button>

            <!-- <div class="w-full lg:text-sm  flex items-center justify-center gap-2 text-xs border-t py-5 border-neutral-400">
                Already have an account? <a href="http://gpa-lawfirm-dev.intelliseven.com.ph/login" class="text-primary font-semibold">Sign In</a>
            </div> -->
        </form>
        </template>
    </Form>
</template>

<script setup>
    import { onMounted, ref, watchEffect } from 'vue';
    import Form from '@/layouts/Form.vue';
    import { useRouter } from 'vue-router';

    const router = useRouter()

    const submitDisabled = ref(true)

    const name = ref('')
    const email = ref('')
    const contact = ref('')

    const nameWarning = ref(null)
    const emailWarning = ref(null)
    const contactWarning = ref(null)


    watchEffect(() => {
        if(!containsNumberOrInvalidCharacters(name.value) && name.value.length &&
        isValidEmail(email.value) && 
        isValidPhoneNumber(contact.value)) submitDisabled.value = false
        else submitDisabled.value = true
    })

    function showWarning(warning, message = null){
        warning.value.innerText = message ? message : ''
    }

    function submitData(){

        // Submit data code...
        
        router.push('/thankyou/2')

        // resetFields()
    }

    function resetFields(){
        name.value = ''
        email.value = ''
        contact.value = ''
    }

    function formatPhoneNumber(phoneNumber) {
        const digitsOnly = phoneNumber.replace(/\D/g, '');
        const formattedNumber = digitsOnly.replace(/(\d{2})(\d{3})(\d{4})/, '09$1 $2 $3');

        return formattedNumber;
    }

    function validateField(input){
        if(input == 'name'){
            if(containsNumberOrInvalidCharacters(name.value)) showWarning(nameWarning, 'Name can only contain alphabetic characters')
            else showWarning(nameWarning)
        }
        else if(input == 'email'){
            if(!email.value.length) showWarning(emailWarning)
            else if(!isValidEmail(email.value)) showWarning(emailWarning, 'Invalid email format (ex. email@gmail.com)')
            else showWarning(emailWarning)
        }
        else if(input == 'contact'){
            if(!contact.value) showWarning(contactWarning)
            else if(!isValidPhoneNumber(contact.value)) showWarning(contactWarning, 'Invalid contact number format (ex. 09XX XXX XXXX)')
            else showWarning(contactWarning)
        }
    }

    function containsNumberOrInvalidCharacters(str) {
        const numberRegex = /\d/;
        const invalidCharRegex = /[^a-zA-Z0-9\s,\.]/;

        if (numberRegex.test(str)) {
            return true;
        }

        if (invalidCharRegex.test(str)) {
            return true;
        }

        return false;
    }

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function isValidPhoneNumber(phoneNumber) {
        const phoneRegex = /^09(\s?\d){9}$/;
        return phoneRegex.test(phoneNumber);
    }

</script>
