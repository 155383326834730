<template>
  <div class="shadow-lg m-3  md:aspect-square w-full max-w-[500px] select-none bg-[#FFFAFA] flex flex-col items-start gap-10 rounded-2xl border-2 border-primary/10 p-5 sm:p-8 lg:px-7 xl:px-10 lg:py-10">
    <svg class="text-secondary" xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 8 8"><path fill="currentColor" d="M3 1C1.35 1 0 2.35 0 4v3h3V4H1c0-1.11.89-2 2-2zm5 0C6.35 1 5 2.35 5 4v3h3V4H6c0-1.11.89-2 2-2z"/></svg>
    <p class="font-medium text-left text-sm lg:text-base leading-10 truncate text-wrap">
      The LawSys has been a game-changer for our legal practice. Its user-friendly interface and robust features have streamlined our case management, making our workflow more efficient than ever. Highly recommend!
      <br><br>
      Its user-friendly interface and robust features have streamlined our case management. Highly recommend!    
    </p>
    <div class="flex flex-row justify-center items-start gap-2">
        <img draggable="false" class="w-10 rounded-full border-primary border" src="./../../assets/prof.png" alt="">
        <div class="flex flex-col">
          <span class="text-sm font-bold">Irene S. Velasco</span>
          <span class="text-sm font-base">Attorney</span>
        </div>
    </div>
    
  </div>
</template>

<script>
import { watch } from 'vue';
export default {
    
}
</script>

<style>

</style>