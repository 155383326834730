<template>
  <div class="w-full flex justify-center items-center">
    <div class="hero-parent">
      <img class="floatC w-full absolute z-20 left-1/2  -translate-x-1/2 bottom-0" src="@/assets/v2/hero/laptop1.png" alt="laptop">
      <img class="
      w-24 floatA absolute z-20 -right-5 -bottom-5
      md:w-32 md:bottom-0
      xl:w-[unset] xl:-right-28 xl:-bottom-5
      " src="@/assets/v2/hero/phone.png" alt="phone">
      <img class="
      w-24 floatB absolute z-20 left-2 bottom-12
      md:w-32 md:left-2 md:bottom-20
      xl:w-[unset] xl:-left-16 xl:top-28
      " src="@/assets/v2/hero/tablet.png" alt="tablet">
      <!-- <img draggable="false" class="lg:hidden w-full hidden absolute right-0 z-[-1]" src="@/assets/v2/hero/WARP.png" alt=""> -->
    </div>
  </div>
</template>

<script>
</script>

<style></style>
