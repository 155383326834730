<template>
  <div
    :delay="500"
    class="flex flex-col gap-7 sm:gap-5 max-w-[600px] lg:p-5">
        <h3 class="text-4xl font-extrabold "><span class="text-primary">{{highlight}}</span> {{ plain }}</h3>
        <p class="opacity-60 font-semibold text-sm lg:text-lg max-w-[500px] text-pretty">{{ content }}</p>
        <!-- <button class="group flex flex-row flex-nowrap justify-center items-center gap-2 w-full md:max-w-52 bg-primary hover:bg-secondary duration-200 font-bold text-xl rounded-full px-4 py-3 text-white"> 
            Get LawSys
            <svg class="group-hover:translate-x-3 duration-200" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m19 12l12 12l-12 12"/></svg>
        </button> -->
    </div>
</template>

<script setup>
const props = defineProps({
    content: {
        type: String,
    },
    title: {
        type: String,
    }
})

const extractedWords = props.title == "User Access and Permission" ? extractWords(props.title, 2) : extractWords(props.title, 1)
const highlight = extractedWords[0]
const plain = extractedWords[1]

function extractWords(inputString, numberOfWords) {
    // Split the input string into an array of words
    const words = inputString.split(' ');
    
    // Extract the specified number of words
    const extractedWords = words.slice(0, numberOfWords).join(' ');
    
    // Get the remaining words
    const remainingWords = words.slice(numberOfWords).join(' ');

    return [extractedWords, remainingWords];
}
</script>

<style>

</style>