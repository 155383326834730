<template>
  <div class="w-full relative">
    <img class="w-full h-1/2 absolute lg:h-[90%] z-[-1]" src="./../../assets/v2/demo/thankyou_warp.png" alt="">

    <div class="max-w-[500px] mx-auto text-center flex flex-col gap-5 lg:gap-7 justify-start items-center h-screen pt-40 px-3">
        <h1 class="text-4xl text-primary font-bold lg:font-extrabold lg:text-5xl">{{ type == 1 ? text[0].header : text[1].header}}</h1>

        <p class="text-pretty font-semibold">{{ type == 1 ? text[0].p1 : text[1].p1}}</p>

        <p class="text-pretty font-semibold">{{ type == 1 ? text[0].p2 : text[1].p2}}</p>
    
        <router-link to="/" class="hover:cursor-pointer hover:bg-secondary duration-200 group flex flex-nowrap gap-1 justify-center items-center rounded-full bg-primary text-white font-bold px-3 py-2 w-full max-w-48">
            Go Back Home
            <svg class="group-hover:w-5 group-hover:translate-x-3 w-0 h-5 duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="currentColor" fill-rule="evenodd" d="m2.87 7.75l1.97 1.97a.75.75 0 1 1-1.06 1.06L.53 7.53L0 7l.53-.53l3.25-3.25a.75.75 0 0 1 1.06 1.06L2.87 6.25h9.88a3.25 3.25 0 0 1 0 6.5h-2a.75.75 0 0 1 0-1.5h2a1.75 1.75 0 1 0 0-3.5z" clip-rule="evenodd"/></svg>
        </router-link>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router"

const route = useRoute()

const type = route.params.type

const text = [
    {
        header: 'Thank You',
        p1: `We've received your request demo and are excited to show you our system in action.`,
        p2: `Please wait momentarily as we prepare to schedule your personalized demo. Our team will email you shortly with further details.`
    },
    {
        header: `Thank you for choosing Lawsys!`,
        p1: ` We appreciate your trust in our services and are committed to providing you with the best possible experience.`,
        p2: `Your request has been received, and we are currently processing it. Our team will email you shortly with further details.`
    },
]

</script>

<style>

</style>