<template>
  <div id="benefits" class="pt-16 w-full flex flex-col items-center px-3 gap-12 relative max-w-[1200px mx-auto]">
    <!-- <img class="features-bg" src="./../../assets/Warp.png" alt=""> -->
    <h2 class="text-2xl md:text-3xl font-extrabold text-center z-40 max-w-[700px]">
      Experience <span class="text-primary">numerous of benefits</span> that elevate your legal practice and management
    </h2>
    <div class="w-full max-w-[1200px] grid grid-cols-1 lg:grid-cols-2 gap-8">

        <BenefitBlock 
        :icoIndex="0" 
        title="Accessibility" 
        content="LawSys ensures access across all devices that facilitates flexible work arrangements and inclusive legal practice."
        imgStyle="h-28 lg:h-32"
        />

        <BenefitBlock 
        :icoIndex="2" 
        title="Enhanced Collaboration" 
        content="LawSys facilitate seamless teamwork, it promotes knowledge sharing and real-time communication, fostering efficiency in legal workflows."
        imgStyle="w-28 lg:w-32"
        />

        <BenefitBlock 
        :icoIndex="1" 
        title="Improved Client Service" 
        content="At LawSys, legal professionals can enhance client service through streamlined communication and easy access to case information, fostering stronger client relationships."
        imgStyle="w-28 lg:w-32"
        />

        <BenefitBlock 
        :icoIndex="4" 
        title="Real-Time Updates" 
        content="With instant updates on case developments, our system enables timely decision-making and proactive client engagement."
        imgStyle="w-24 lg:w-32"
        />

        <BenefitBlock 
        :icoIndex="3" 
        title="Increased Productivity" 
        content="LawSys centralizes case management and automates routine tasks, empowering lawyers to focus on high-value activities and achieve more in less time."
        imgStyle="w-28 lg:w-32"
        />
      
        <BenefitBlock 
        :icoIndex="5" 
        title="Compliance and Security" 
        content="LawSys safeguards sensitive client data and ensures adherence to legal regulations that foester trust in legal operations."
        imgStyle="w-28 lg:w-32"
        />

        
      
    </div>
  </div>
</template>
<script>
import BenefitBlock from "./BenefitBlock.vue";

export default {
  components: {
    BenefitBlock,
  },
};
</script>
