<template>
  <div class="mt-40 flex flex-col w-full items-center gap-3">
    <h1 class="text-primary font-extrabold text-8xl">404</h1>
    <span class="text-neutral-700 font-bold text-2xl">Page Not Found</span>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>