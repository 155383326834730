<template>
  <div class="mx-auto max-w-96 lg:max-w-none rounded-xl min-h-56 shadow-xl w-full max-h-none md:max-h-64 group lg:min-h-64 duration-1000 relative overflow-hidden z-40" @mouseenter="hovering = true" @mouseleave="hovering = false">
    <div class="w-full p-5 lg:p-10 z-20  h-full hover:border-primary/20 duration-500 flex flex-col md:flex-row bg-white rounded-xl  gap-4 border-2 border-[#847FFC]/20 sm:[4/3] ">
        
        <div class="w-full md:w-2/3 flex flex-col justify-center gap-3">
          <div class="flex flex-row gap-2 items-center justify-start"> 
            <div class="text-base font-bold lg:text-xl">{{ title }}</div>
          </div>

          <p class="w-full text-sm text-pretty">{{ content }}</p>
        </div>

        <div class="w-full md:w-1/3 flex justify-center items-center">
          <img draggable=false 
            :class="imgStyle"
            class="object-contain object-center"
            :src="imagesPath[icoIndex]" 
            alt="accessibility_logo">
        </div>
    </div>
 </div>
</template>

<script setup>
import {  computed, onMounted, onUnmounted, ref } from 'vue';
import { useGetDeviceType } from '@/composables/useGetDeviceType';

  const { width } = useGetDeviceType()  

  const hovering = ref(false)
  const removeFirst = computed(() => {
    const tempArr = [...props.content]
    tempArr.shift()
    return tempArr
  })

  const props = defineProps({
    icoIndex: {
      type: Number
    },
    title: {
      type: String
    },
    content: {
      type: String
    },
    imgStyle: {
      type: String
    }
  })


  const accessibility = require('./../../assets/v2/benefits/accessibility.png');
  const client = require('./../../assets/v2/benefits/client.png');
  const collab = require('./../../assets/v2/benefits/collab.png');
  const productivity = require('./../../assets/v2/benefits/productivity.png');
  const realtime = require('./../../assets/v2/benefits/realtime.png');
  const security = require('./../../assets/v2/benefits/security.png');

  let interval;

  const imagesPath = 
  [accessibility,
  client,
  collab,
  productivity,
  realtime,
  security
  ]

  console.log(props.icoIndex)

let characterIndex = 0;

function updateCharacters() {
  const characterSpans = document.querySelectorAll('span[ref="characterSpan"]');
  if (characterIndex < characterSpans.length) {
    characterSpans[characterIndex].classList.add('highlight');
    characterIndex++;
  } else {
    clearInterval(interval);
  }
}

onMounted(() => {
  interval = setInterval(updateCharacters, 100);
});

onUnmounted(() => {
  clearInterval(interval);
});

</script>


<!-- TODO
MAKE MAXHEIGHT DYNAMIC PARA DI MALAKI EMPTY SPACE

ADD MAX WIDTH SA PANGALAWA ANG UP NA PARAGRAPHS PARA PEDE ITAAS NG KONTI YUNG IMAGE

-->