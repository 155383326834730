<template>
  <div class="w-full relative">
    <img class="w-full h-80 absolute z-[-1] lg:hidden" src="./../assets/v2/demo/Subtract.png" alt="">
    <img class="hidden lg:block h-full w-[55%] left-0 absolute z-[-1]" src="./../assets/v2/demo/Warp.png" alt="">


    <div class="w-full lg:max-w-[1400px] lg:mx-auto flex flex-col lg:flex-row lg:items-start items-center gap-24 min-h-screen">
        <div class="lg:w-1/2 lg:h-screen max-w-[32rem] lg:ml-6 xl:ml-20 lg:max-w-none px-5 py-8 pt-16 flex justify-start flex-col z-10 lg:gap-6 gap-3 text-white">
            <div class="h-1/4 hidden lg:flex gap-2 justify-start items-start mb-10">
                <img class="w-10" src="./../assets/LOGO/LOGO.png" alt="LawSys logo">
                <h1 class="text-4xl font-bold">LAWSYS</h1>
            </div>
            
            <div class="h-3/4 flex flex-col justify-start gap-3 lg:gap-6">
                <slot name="text"></slot>    
            </div>
        </div>

        <slot name="form"></slot>
    </div>
  </div>
</template>

<script setup>

</script>
