import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/login/Login.vue'
import GetLawSys from '../views/getLawSys/GetLawSys.vue'
import Demo from '../views/demo/Demo.vue'
import ThankYou from '../views/thankyou/ThankYou.vue'
import NotFound from '../views/notfound/NotFound.vue'
import PrivacyPolicy from '@/views/legalities/PrivacyPolicy.vue'
import TermsCondition from '@/views/legalities/TermsCondition.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/getlawsys',
    name: 'GetLawSys',
    component: GetLawSys
  },
  {
    path: '/demo',
    name: 'Demo',
    component: Demo
  },
  {
    path: '/thankyou/:type',
    name: 'ThankYou',
    component: ThankYou
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms',
    name: 'TermsCondition',
    component: TermsCondition
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
