<template>
    <div class="w-full h-[70vh] lg:h-[80vh] lg:mt-20 justify-center items-start flex relative">
        <div class="flex flex-col items-center gap-7 lg:px-[10vw] px-3">
            <img class="z-40 w-20 md:w-28" src="./../../assets/LOGO/LOGO.png" alt="">
            <div  class="w-full z-10 flex flex-col items-center justify-center gap-5 lg:w-2/3">
                <h2 class="lg:text-6xl  font-bold text-3xl text-center">Start your journey with <span class="text-primary">LawSys</span> today</h2>
                <span class="opacity-60 max-w-[600px] text-center text-pretty">
                    Ready to see LawSys in action? Request a demo with us today! Explore a wide range of features that simplifies your law firm operations fast and with ease.
                </span>
                <div
                class="flex flex-row w-full items-center text-white gap-2 justify-center sm:gap-5"
                >
                    <router-link to="/getlawsys"
                        class="w-full py-3 px-5 group flex flex-row flex-nowrap justify-center items-center gap-2 max-w-64 bg-primary hover:bg-secondary duration-200 font-bold text-sm lg:text-lg rounded-full p-3"
                    >
                        Request a Demo
                        <svg class="group-hover:translate-x-3 duration-200" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m19 12l12 12l-12 12"/></svg>
                    </router-link>
                    <!-- <router-link to="/demo"
                        class="w-1/2 px-3 sm:px-5  group flex flex-row flex-nowrap justify-center items-center md:max-w-56 gap-2 duration-200  font-bold text-xs md:text-sm lg:text-lg p-3 rounded-full bg-transparent text-neutral-700"
                    >
                        Get Demo
                        <svg class="group-hover:translate-x-3 duration-200" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m19 12l12 12l-12 12"/></svg>
                    </router-link> -->
                </div>
            </div>
        </div>

        <img class="absolute w-full h-[150%] -top-40 md:h-[200%] md:-top-64 object-fill object-center" src="./../../assets/v2/Subtract.png" alt="">
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>