<template>
  <div class="w-full h-screen bg-white flex items-center justify-center">
      <form class="max-w-96 bg-white shadow-[0_0_3px_rgb(0,0,0,0.3)] w-full mx-5 rounded-lg p-4 flex flex-col justify-between gap-5 lg:gap-8" action="">
        <div class="flex flex-col items-center">
          <img class="w-14 h-14" src="./../../assets/LOGO/LOGO.png" alt="">
          <span class="text-xs lg:text-sm">LAWFIRM MANAGEMENT SYSTEM</span>
        </div>

        <div class="flex flex-col items-center justify-center font-semibold">
          <span class="text-sm">Sign In</span>
          <span class="text-xs">Login to your account</span>
        </div>

        <div class="flex flex-col gap-5">
          <div>
            <div>
              <label class="text-sm" for="username">Username</label>
            </div>

            <div class="p-2 gap-2 rounded-md border border-gray-300 flex flex-row flex-nowrap justify-start items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 15 15"><path fill="gray" fill-rule="evenodd" d="M7.5.875a3.625 3.625 0 0 0-1.006 7.109c-1.194.145-2.218.567-2.99 1.328c-.982.967-1.479 2.408-1.479 4.288a.475.475 0 1 0 .95 0c0-1.72.453-2.88 1.196-3.612c.744-.733 1.856-1.113 3.329-1.113s2.585.38 3.33 1.113c.742.733 1.195 1.892 1.195 3.612a.475.475 0 1 0 .95 0c0-1.88-.497-3.32-1.48-4.288c-.77-.76-1.795-1.183-2.989-1.328A3.627 3.627 0 0 0 7.5.875M4.825 4.5a2.675 2.675 0 1 1 5.35 0a2.675 2.675 0 0 1-5.35 0" clip-rule="evenodd"/></svg>
              <input class="w-full focus:outline-none text-sm" type="text" name="" id="username">
            </div>
            
          </div>

          <div>
            <div class="flex justify-between">
              <label class="text-sm" for="password">Password</label>
              <span class="text-xs font-semibold text-gray-400">Forget Password?</span>
            </div>

            <div class="p-2 gap-2 rounded-md border border-gray-300 flex flex-row flex-nowrap justify-start items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24"><g fill="none" stroke="gray" stroke-width="1.5"><path d="M2 16c0-2.828 0-4.243.879-5.121C3.757 10 5.172 10 8 10h8c2.828 0 4.243 0 5.121.879C22 11.757 22 13.172 22 16c0 2.828 0 4.243-.879 5.121C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.879C2 20.243 2 18.828 2 16Z"/><path stroke-linecap="round" d="M6 10V8a6 6 0 1 1 12 0v2"/></g></svg>
              <input class="w-full focus:outline-none text-sm" type="password" name="" id="password">
              <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 24 24"><path fill="gray" d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0"/></svg>
            </div>            
          </div>

          <router-link to="/" class="text-center bg-secondary text-white w-full py-2 rounded-md">Log in</router-link>
        </div>

        <div class="text-xs text-center">Developed by <span class="font-semibold">Intelliseven Technology Solution Inc.</span></div>
      </form>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>