<template>
  <div  class="mx-auto gap-24 w-full overflow-x-hidden min-w-0 h-fit flex flex-col md:gap-32">
    <LandingPage />
    <!-- <Intelliseven /> -->
    <Features />
    <Mobility />
    <Benefits />
    <!-- <Testimonials /> -->
    <EndNote />
    <FooterVue />
  </div>
</template>

<script setup>
import LandingPage from "./landingpage/LandingPage.vue";
import Intelliseven from "@/components/Intelliseven.vue"
import Features from "@/views/features/Features.vue"
import Mobility from './mobility/Mobility.vue';
import Benefits from "@/views/benefits/Benefits.vue"
import Testimonials from './testimonials/Testimonials.vue';
import EndNote from './endnote/EndNote.vue';
import FooterVue from './footer/Footer.vue';
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";

const route = useRoute();
const router = useRouter();

onMounted(() => {
  if(route.query.offset){
    goTo(route.query.offset)
  }
})

const goTo = (id) =>{
  if(route.path == "/"){
    let topPosition = document.getElementById(id).offsetTop - 100;
    if(id == 'overview') topPosition -= 130;
    window.scrollTo({
        top: topPosition,
        left: 0,
        behavior: 'smooth',
    }); 
    
    removeQueryParam("offset")
  }
}

const removeQueryParam = (paramNameToRemove) => {
  const { query, params, fullPath } = router.currentRoute.value;

  const newQuery = { ...query };
  delete newQuery[paramNameToRemove];

  router.replace({ path: fullPath, query: newQuery, params });
};

</script>
