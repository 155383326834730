<template>
  <div id="testimonials" class="w-full md:mt-64 flex flex-col gap-7 relative">
      <img class="features-bg" src="./../../assets/v2/Subtract.png" alt="">

      <div class="w-full flex justify-center items-center px-5 sm:px-[10vw]">
        <div class="w-fit flex flex-col items-center gap-2 z-10">
          <h3 class="sm:text-xl text-primary font-bold">Testimonials</h3>
          <h2 class="text-2xl font-bold text-center z-10 sm:text-4xl">
            What do our <span class="text-primary">Users</span> say?
          </h2>
          <p class="opacity-60 text-center font-medium text-sm z-10 sm:text-base md:max-w-[400px]">
            From solo practitioners to large law firms, our platform has helped countless users
          </p>
        </div>
        <!-- <div class="max-md:hidden flex gap-10 z-50">
          <button >
            <svg  class="rotate-180" xmlns="http://www.w3.org/2000/svg" width="3rem" height="3rem" viewBox="0 0 48 48"><defs><mask id="ipSRightC0"><g fill="none" stroke-linejoin="round" stroke-width="4"><path fill="#fff" stroke="#fff" d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z"/><path stroke="#000" stroke-linecap="round" d="m21 33l9-9l-9-9"/></g></mask></defs><path fill="currentColor" d="M0 0h48v48H0z" mask="url(#ipSRightC0)"/></svg>
          </button>
          <button>
            <svg  xmlns="http://www.w3.org/2000/svg" width="3rem" height="3rem" viewBox="0 0 48 48"><defs><mask id="ipSRightC0"><g fill="none" stroke-linejoin="round" stroke-width="4"><path fill="#fff" stroke="#fff" d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z"/><path stroke="#000" stroke-linecap="round" d="m21 33l9-9l-9-9"/></g></mask></defs><path fill="currentColor" d="M0 0h48v48H0z" mask="url(#ipSRightC0)"/></svg>
          </button>
        </div> -->
      </div>
      <div>
        <swiper
          :modules="modules"
          :slides-per-view="desktopMode ? 3 : tabletMode ? 1 : 1"
          :space-between="70"
          :navigation="false"
          :pagination="false"
          :scrollbar="false"
          :autoplay="{
            delay: 5000,
            disableOnInteraction: false
          }"
          class="px-3"
        >
        <swiper-slide class="flex justify-center items-center ">
          <TestimonialBlock />
        </swiper-slide>
        <swiper-slide class="flex justify-center items-center">
          <TestimonialBlock />
        </swiper-slide>
        <swiper-slide class="flex justify-center items-center">
          <TestimonialBlock />
        </swiper-slide>
        <swiper-slide class="flex justify-center items-center">
          <TestimonialBlock />
        </swiper-slide>
        <swiper-slide class="flex justify-center items-center">
          <TestimonialBlock />
        </swiper-slide>
        <swiper-slide class="flex justify-center items-center">
          <TestimonialBlock />
        </swiper-slide>

        <div class="swiper-button-prev">prev</div>
      <div class="swiper-button-next">next</div>
      </swiper>
      </div>
      
  </div>
</template>
<script>
  import TestimonialBlock from './TestimonialBlock.vue';
  import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
  import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';

  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';
  import { ref, watch } from 'vue';

  export default {
    components: {
      Swiper,
      SwiperSlide,
      TestimonialBlock,
    },
    setup() {
      const swiper = useSwiper();

      console.log(swiper)

      const desktopMode = ref(window.innerWidth >= 1024)
      const tabletMode= ref(window.innerWidth >= 624)

      window.addEventListener("resize", function () {
          desktopMode.value = window.innerWidth >= 1024;
          tabletMode.value = window.innerWidth >= 624;
      });

      return {
        modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay], desktopMode, tabletMode, swiper,
      };
    },
  };
</script>