import { ref } from "vue";

export function useGetDeviceType(callback){
    const desktopMode = ref(window.innerWidth >= 1024)
    const width = ref(window.innerWidth)

    window.addEventListener("resize", function () {
        desktopMode.value = window.innerWidth >= 1024;
        width.value = window.innerWidth

        if(callback) callback()
    });

    return {
        desktopMode,
        width
    }
}