<template>
  <nav
    :class="{
      hidden: !showNavigation || isDemo,
    }"
    class="z-[100] w-full backdrop-blur-[50px] fixed top-0 justify-between flex px-3 py-4 items-center xl:justify-center lg:px-[10vw] shadow-lg"
  >
    <div class="w-4/6 flex flex-row gap-[2vw]">
      <div class="flex flex-row gap-2 justify-center items-center">
        <img
          src="./../assets/LOGO/LOGO.png"
          alt=""
          class="w-6 aspect-square object-cover object-center"
        />
        <span class="font-extrabold text-primary leading-7 text-2xl"
          >LAWSYS</span
        >
      </div>

      <div
        v-show="desktopMode"
        class="flex flex-nowrap items-center justify-start gap-[2vw]"
      >
        <NavigationItem text="Overview" linkTo="overview" />
        <NavigationItem text="Features" linkTo="features" />
        <NavigationItem text="Benefits" linkTo="benefits" />
        <!-- <NavigationItem text="Testimonials" linkTo="testimonials" /> -->
      </div>
    </div>
    <div
      @click="toggleMobileMenu"
      v-show="!desktopMode"
      class="burger-container flex items-center justify-center"
    >
      <div class="custom-burger"></div>
    </div>
    <div
      v-show="desktopMode"
      class="w-2/6 lg:gap-x-10 flex flex-row flex-nowrap justify-end items-center"
    >
      <div
        class="flex w-full flex-row flex-nowrap gap-[1vw] items-center justify-end"
      >
        <!-- <a
          target="_blank"
          href="http://gpa-lawfirm-dev.intelliseven.com.ph/login"
          class="text-neutral-700 hover:text-primary hover:bg-primary/10 px-3 py-2 rounded-full w-fit font-bold text-base duration-200"
          >Sign In</a
        > -->
        <router-link
          to="/getlawsys"
          class="group flex flex-row flex-nowrap justify-center items-center gap-2 hover:bg-secondary text-white font-bold text-sm lg:text-lg bg-primary py-3 px-5 duration-200 rounded-full"
        >
          Request a demo
          <svg
            class="group-hover:translate-x-3 duration-200"
            xmlns="http://www.w3.org/2000/svg"
            width="1.5rem"
            height="1.5rem"
            viewBox="0 0 48 48"
          >
            <path
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
              d="m19 12l12 12l-12 12"
            />
          </svg>
        </router-link>
      </div>
    </div>
  </nav>
  <div
    :class="{
      slideToLeft: isMobileMenuOpen,
      revertSlideToLeft: !isMobileMenuOpen && mobileMenuClicked,
      'translate-x-[100%]': !isMobileMenuOpen && !mobileMenuClicked,
      hidden: isDemo,
    }"
    class="flex flex-col max-w-[600px] w-full h-full fixed bg-bgcolor top-0 z-[210] px-5 py-4 gap-7"
  >
    <div class="w-full flex flex-row justify-between items-center">
      <div class="lg:w-1/6 flex row gap-1">
        <img src="./../assets/LOGO/LOGO.png" alt="" class="w-7" />
        <span class="font-extrabold text-primary leading-7 text-xl"
          >LAWSYS</span
        >
      </div>
      <button @click="toggleMobileMenu">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.2rem"
          height="1.2rem"
          viewBox="0 0 24 24"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
            />
            <path
              fill="currentColor"
              d="m12 14.122l5.303 5.303a1.5 1.5 0 0 0 2.122-2.122L14.12 12l5.304-5.303a1.5 1.5 0 1 0-2.122-2.121L12 9.879L6.697 4.576a1.5 1.5 0 1 0-2.122 2.12L9.88 12l-5.304 5.304a1.5 1.5 0 1 0 2.122 2.12z"
            />
          </g>
        </svg>
      </button>
    </div>
    <button
      @click="() => goTo('overview')"
      class="text-left hover:cursor-pointer hover:text-secondary w-full font-extrabold"
    >
      Overview
    </button>
    <button
      @click="() => goTo('features')"
      class="text-left hover:cursor-pointer hover:text-secondary w-full font-extrabold"
    >
      Features
    </button>
    <button
      @click="() => goTo('benefits')"
      class="text-left hover:cursor-pointer hover:text-secondary w-full font-extrabold"
    >
      Benefits
    </button>
    <!-- <button
      @click="() => goTo('testimonials')"
      class="text-left hover:cursor-pointer hover:text-secondary w-full font-extrabold"
    >
      Testimonial
    </button> -->
    <!-- <a
      target="_blank"
      href="http://gpa-lawfirm-dev.intelliseven.com.ph/login"
      class="hover:cursor-pointer hover:text-secondary w-full font-bold text-primary"
      to="/"
      >Sign In</a
    > -->
    <router-link
      @click="toggleMobileMenu"
      class="mx-auto max-w-72 group flex flex-row flex-nowrap justify-center items-center gap-2 w-full hover:bg-secondary duration-200 font-bold px-2 py-3 text-sm text-white bg-primary text-center rounded-full"
      to="/getlawsys"
    >
       Request a demo
      <svg
        class="group-hover:translate-x-3 duration-200"
        xmlns="http://www.w3.org/2000/svg"
        width="1.5rem"
        height="1.5rem"
        viewBox="0 0 48 48"
      >
        <path
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
          d="m19 12l12 12l-12 12"
        />
      </svg>
    </router-link>
  </div>
</template>

<script>
import NavigationItem from "./NavigationItem.vue";
import { getCurrentInstance, ref, watch, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useGetDeviceType } from "@/composables/useGetDeviceType";

export default {
  setup() {
    const route = useRoute();
    const isDemo = ref(false);
    const showNavigation = ref(route.path !== "/login");
    const { desktopMode } = useGetDeviceType(() => {
      if (desktopMode.value) isMobileMenuOpen.value = false;
    });

    if (
      route.path === "/getlawsys" ||
      route.path === "/demo" ||
      /^\/thankyou\/(1|2)$/.test(route.path)
    )
      isDemo.value = true;

    watchEffect(() => {
      if (
        route.path === "/getlawsys" ||
        route.path === "/demo" ||
        /^\/thankyou\/(1|2)$/.test(route.path)
      )
        isDemo.value = true;
      else isDemo.value = false;
    });

    const goTo = (id) => {
      let topPosition = document.getElementById(id).offsetTop - 100;
      if (id == "overview") topPosition -= 130;
      window.scrollTo({
        top: topPosition,
        left: 0,
        behavior: "smooth",
      });

      isMobileMenuOpen.value = false;
      instance.emit("toggleFilter");
    };

    watch(
      () => route.path,
      (newPath, oldPath) => {
        showNavigation.value = newPath !== "/login";
        console.log(newPath !== "/login");
      }
    );

    const isMobileMenuOpen = ref(false);
    const mobileMenuClicked = ref(false);
    const instance = getCurrentInstance();

    const toggleMobileMenu = () => {
      isMobileMenuOpen.value = !isMobileMenuOpen.value;
      mobileMenuClicked.value = true;

      instance.emit("toggleFilter");
    };

    return {
      goTo,
      desktopMode,
      isMobileMenuOpen,
      toggleMobileMenu,
      mobileMenuClicked,
      showNavigation,
      isDemo,
    };
  },
  components: {
    NavigationItem,
  },
};
</script>
