<template>
  <a @click="goTo" class="hover:cursor-pointer hover:text-primary hover:bg-primary/10 px-3 py-2 rounded-full text-neutral-900 font-bold text-base duration-200">{{ text }}</a>
</template>

<script setup>
  import { useRoute, useRouter } from 'vue-router';
  
  const route = useRoute();
  const router = useRouter();
  const props = defineProps({
    text: {
      type: String,
      default: ""
    },
    linkTo: {
      type: String,
      default: "#",
    }
  })


  

  const goTo = () =>{
    if(route.path == "/"){
      let topPosition = document.getElementById(props.linkTo).offsetTop - 100;
      if(props.linkTo == 'overview') topPosition -= 130;
      window.scrollTo({
          top: topPosition,
          left: 0,
          behavior: 'smooth',
      }); 
      
      removeQueryParam("offset")
    }
    else{
      router.push({ path: "/", query: { offset: props.linkTo } });
    }
  }

  const removeQueryParam = (paramNameToRemove) => {
    const { query, params, fullPath } = router.currentRoute.value;

    const newQuery = { ...query };
    delete newQuery[paramNameToRemove];

    router.replace({ path: fullPath, query: newQuery, params });
  };
</script>
