<template>
  <div class="select-none grow-parent relative bg-gradient-to-b from-primary from-50% via-[#f6cfd5] via-70% to-bgcolor to-100% p-[2px] pb-0 rounded-t-lg md:rounded-t-3xl">
    <div class="flex flex-col gap-2 bg-bgcolor rounded-t-lg p-2 md:p-5 md:rounded-t-3xl">
        <div class="text-[10px] font-bold md:text-sm text-primary">FEBRUARY 2024</div>
        <table class="border-collapse">
            <thead>
                <tr class="text-primary font-semibold text-[8px]">
                    <th class="border-2 border-neutral-300 px-1 font-extrabold">SUN</th>
                    <th class="border-2 border-neutral-300 px-1 font-extrabold">MON</th>
                    <th class="border-2 border-neutral-300 px-1 font-extrabold">TUE</th>
                    <th class="border-2 border-neutral-300 px-1 font-extrabold">WED</th>
                    <th class="border-2 border-neutral-300 px-1 font-extrabold">THU</th>
                    <th class="border-2 border-neutral-300 px-1 font-extrabold">FRI</th>
                    <th class="border-2 border-neutral-300 px-1 font-extrabold">SAT</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <Td :inside="false">28</Td>
                    <Td :inside="false">29</Td>
                    <Td :inside="false">30</Td>
                    <Td :grow="true" :inside="false" mode="gray">31</Td>
                    <Td>1</Td>
                    <Td :grow="true" mode="red">2</Td>
                    <Td>3</Td>
                </tr>
                <tr>
                    <Td>4</Td>
                    <Td>5</Td>
                    <Td>6</Td>
                    <Td>7</Td>
                    <Td :grow="true" mode="gray">8</Td>
                    <Td>9</Td>
                    <Td>10</Td>
                </tr>
                <tr>
                    <Td>11</Td>
                    <Td>12</Td>
                    <Td mode="gray">13</Td>
                    <Td mode="gray">14</Td>
                    <Td mode="gray">15</Td>
                    <Td>16</Td>
                    <Td>17</Td>
                </tr>
                <tr>
                    <Td>18</Td>
                    <Td>19</Td>
                    <Td>20</Td>
                    <Td>21</Td>
                    <Td>22</Td>
                    <Td :grow="true" mode="gray">23</Td>
                    <Td>24</Td>
                </tr>
                <tr>
                    <Td>25</Td>
                    <Td>26</Td>
                    <Td mode="gray">27</Td>
                    <Td mode="gray">28</Td>
                    <Td mode="red">29</Td>
                    <Td :inside="false">1</Td>
                    <Td :inside="false">2</Td>
                </tr>
            </tbody>
        </table>
    </div>

    <ButtonGroup v-if="desktopMode" v-motion-slide-visible-once-left :delay="1000" />
    <ButtonGroup v-else />
  </div>
</template>

<script setup>
import { useGetDeviceType } from '@/composables/useGetDeviceType'
import Td from '@/views/features/calendar/Td.vue'
import ButtonGroup from './ButtonGroup.vue'

const { desktopMode } = useGetDeviceType()

</script>

<style>

</style>