<template>
    <div class="min-w-[280px] sm:min-w-[500px] group select-none case-parent relative bg-gradient-to-b from-primary from-50% via-[#f6cfd5] via-70% to-bgcolor to-100% p-[2px] pb-0 rounded-t-lg md:rounded-t-3xl">
        <div class="sm:text-sm text-xs bg-gradient-to-b from-bgcolor from-50% via-white via-70% to-white/50 to-100% rounded-t-lg px-5 py-3 flex flex-col md:rounded-t-3xl divide-y divide-neutral-400">
            <div class="font-bold text-primary py-3">
                List of Employees
            </div>

            <div class="font-bold text-primary py-3">
                First / Company Name /  Client ID
            </div>

            <div class="py-3 flex flex-col gap-1">
                <div class="font-bold">System Admin</div>
                <div>admin-sven</div>
                <div>EMP-1</div>
            </div>

            <div class="py-3 flex flex-col gap-1">
                <div class="font-bold">Aivan F. Vitalista</div>
                <div>admin.vitalista</div>
                <div>EMP-1</div>
            </div>

            <div class="py-3 flex flex-col gap-1">
                <div class="font-bold">Bryan P. Tomenio</div>
                <div>bryan.tomenio</div>
                <div>EMP-1</div>
            </div>

            <div class="py-3 flex flex-col gap-1">
                <div class="font-bold">Mark Jumar B. Perez</div>
                <div>markjumar.perez</div>
                <div>EMP-1</div>
            </div>
        </div>

        <ButtonGroup v-if="desktopMode" v-motion-slide-visible-once-right :delay="1000" />
        <ButtonGroup v-else />
    </div>
</template>

<script setup>
import { useGetDeviceType } from "@/composables/useGetDeviceType";
import ButtonGroup from "../employee/ButtonGroup.vue";

const { desktopMode } = useGetDeviceType()

</script>

<style>

</style>