<template>
  <div class="duration-1000 flex border border-neutral-400 rounded-lg p-3 border-r-8 border-r-neutral-400 md:border-r-red-700 md:group-hover:border-r-neutral-400">
    <div class="w-1/3 flex flex-col gap-2 items-start justify-start border-r border-neutral-400">
        <div class="text-[8px] md:text-xs flex flex-col items-start justify-start">
            <span>File No:</span>
            <span class="font-bold">001-02-CA</span>
        </div>

        <div class="text-[8px] md:text-xs flex flex-col items-start justify-start">
            <span>Handling Lawyer/s:</span>
            <span class="flex items-center justify-center border font-bold border-primary rounded-full p-[2px] w-5 h-5 text-[8px]">AK</span>
        </div>

        <div class="text-[8px] md:text-xs flex flex-col items-start justify-start">
            <span>Client:</span>
            <div class="flex gap-1 font-bold">
                <span class="flex items-center justify-center border font-bold border-primary rounded-full p-[2px] w-5 h-5 text-[8px]">AF</span>
                Alyssa Fajardo
            </div>
            
        </div>
    </div>

    <div class="w-2/3 pl-3 flex flex-col ">
        <div class="text-[8px] md:text-xs pb-2 flex flex-col items-start justify-start border-b border-neutral-400">
            <span>Case Title:</span>
            <span class="font-bold">Alyssa Fajardo vs Miguel Fajardo</span>
        </div>

        <div class="flex pt-2">
            <div class="w-1/2 flex flex-col gap-1 pr-3">
                <div class="text-[8px] md:text-xs flex flex-col items-start justify-start">
                    <span>For:</span>
                    <span class="font-bold">Nutility of Marriage</span>
                </div>

                <div class="text-[8px] md:text-xs flex flex-col items-start justify-start">
                    <span>Case No:</span>
                    <span class="font-bold">01-526-438</span>
                </div>
            </div>
            <div class="border-l border-neutral-400 pl-3 w-1/2 flex flex-col gap-1">
                <div class="text-[8px] md:text-xs flex flex-col items-start justify-start">
                    <span>Court/Tribunal:</span>
                    <span class="font-bold">Trial Court</span>
                </div>

                <div class="text-[8px] md:text-xs flex flex-col items-start justify-start">
                    <span>Branch No/Location:</span>
                    <span class="font-bold">Bulacan</span>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

</script>

<style>

</style>