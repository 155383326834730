<template>
  <div :class="{
    'flex flex-col gap-5 md:gap-10 md:justify-between sm:items-center lg:flex-row relative lg:px-[10vw] p-3 ': isRight,
    'flex flex-col-reverse gap-5 md:gap-10 sm:items-center lg:flex-row relative lg:px-[10vw] p-3 ' : !isRight
}">
    
    <slot name="left"></slot>
    <!-- <FeatureBlockContent v-if="isRight && desktopMode" :content="content" :title="title" v-motion-slide-visible-once-right/>
    <FeatureBlockContent v-else-if="!isRight && desktopMode" :content="content" :title="title" v-motion-slide-visible-once-left/> 
    
    <FeatureBlockContent v-if="isRight && !desktopMode" :content="content" :title="title"/>
    <FeatureBlockContent v-else-if="!isRight && !desktopMode" :content="content" :title="title"/> -->

    <FeatureBlockContent v-if="isRight" :content="content" :title="title" :whiteBgDirection="whiteBgDirection"/>
    <FeatureBlockContent v-else-if="!isRight" :content="content" :title="title"/>
    <slot name="right"></slot>

    <img v-if="isRight && whiteBgDirection == 'toright'" class="w-full h-[150%] top-0 left-0 absolute object-fit -z-10" src="./../../assets/v2/warptoright.png" alt="">
    <img v-if="isRight && whiteBgDirection == 'toleft'" class="w-full h-[150%] bottom-0-0 left-0 absolute object-fit -z-10" src="./../../assets/v2/warptoleft.png" alt="">
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import FeatureBlockContent from './FeatureBlockContent.vue';
import { useGetDeviceType } from '@/composables/useGetDeviceType';

const { desktopMode } = useGetDeviceType()

const props = defineProps({
    content: {
        type: String,
    },
    title: {
        type: String,
    },
    isRight: {
        type: Boolean,
    },
    whiteBgDirection: {
        type: String,
        default: ""
    }
})


</script>