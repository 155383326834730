<template>
  <div id="footer" class="relative flex flex-col gap-16  bg-[#c6203d]">
    <div class="text-white grid grid-cols-1 gap-y-10 gap-x-10 lg:grid-cols-4 px-3 lg:px-[10vw] z-10">
      <div>
        <div class="lg:w-1/6 flex row gap-2 items-center">
          <img src="./../../assets/LOGO/LOGO.png" alt="" class="w-10" />
          <span class="font-extrabold leading-7 text-3xl">LAWSYS</span>
        </div>
        <span class="text-xs lg:text-sm">Law Management Office in <br> your pocket</span>
      </div>
      <div class="flex flex-col gap-2">
        <button @click="() => goTo('overview')" class="hover:bg-white/10 text-left w-fit rounded-full px-3 py-1 duration-200 font-bold text-sm ">Overview</button>
        <button @click="() => goTo('features')" class="hover:bg-white/10 text-left w-fit rounded-full px-3 py-1 duration-200 font-bold text-sm ">Features</button>
        <button @click="() => goTo('benefits')" class="hover:bg-white/10 text-left w-fit rounded-full px-3 py-1 duration-200 font-bold text-sm ">Benefits</button>
        <!-- <button @click="() => goTo('testimonials')" class="hover:bg-white/10 text-left w-fit rounded-full px-3 py-1 duration-200 font-bold text-sm ">Testimonials</button> -->
      </div>
      <div class="flex flex-col gap-2">
        <span class="font-semibold text-base">Contact us</span>
        <div class="flex flex-row flex-nowrap gap-2 items-center justify-start">
          <svg width="1rem" height="1rem" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.54733 0.561523H12.4527C13.4815 0.561523 14 1.04712 14 2.03477V8.96482C14 9.94424 13.4815 10.4381 12.4527 10.4381H1.54733C0.518518 10.4381 0 9.94424 0 8.96482V2.03477C0 1.04712 0.518518 0.561523 1.54733 0.561523ZM6.99588 7.63971L12.5432 3.08827C12.7407 2.92366 12.8971 2.54506 12.6502 2.20761C12.4115 1.87017 11.9753 1.86193 11.6872 2.0677L6.99588 5.24465L2.31276 2.0677C2.02469 1.86193 1.58848 1.87017 1.34979 2.20761C1.10288 2.54506 1.25926 2.92366 1.45679 3.08827L6.99588 7.63971Z" fill="white"/>
          </svg>
          <span class="text-sm max-w-[50px] duration-200 hover:text-red-500 hover:cursor-pointer">hello@intelliseven.com.ph</span>
        </div>
        <div class="flex flex-row flex-nowrap gap-2 items-center justify-start">
          <svg width="1rem" height="1rem" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.218 14.0011C10.6078 14.0011 9.7508 13.7804 8.46741 13.0634C6.90678 12.1882 5.69966 11.3803 4.14747 9.83213C2.65091 8.33651 1.92264 7.36819 0.903366 5.51343C-0.248119 3.41926 -0.0518294 2.32153 0.16759 1.85237C0.428894 1.29163 0.814598 0.956253 1.31314 0.623373C1.5963 0.437846 1.89597 0.278808 2.20832 0.148276C2.23958 0.134836 2.26865 0.12202 2.29459 0.110456C2.44931 0.0407538 2.68373 -0.0645802 2.98067 0.0479428C3.17883 0.122333 3.35574 0.274552 3.63267 0.548045C4.2006 1.10816 4.9767 2.3556 5.26301 2.96823C5.45523 3.38112 5.58245 3.65368 5.58276 3.95937C5.58276 4.31725 5.40272 4.59325 5.18424 4.89112C5.1433 4.94707 5.10266 5.00052 5.06328 5.0524C4.82542 5.36497 4.77322 5.4553 4.8076 5.61658C4.8773 5.94071 5.3971 6.90559 6.25133 7.75796C7.10557 8.61032 8.04264 9.09729 8.36802 9.16668C8.53618 9.20263 8.62838 9.14824 8.95095 8.90194C8.99721 8.86662 9.04472 8.83005 9.09441 8.79348C9.42761 8.54562 9.69079 8.37027 10.0402 8.37027H10.0421C10.3462 8.37027 10.6066 8.50217 11.0379 8.71972C11.6006 9.00352 12.8855 9.76962 13.4491 10.3382C13.7232 10.6145 13.876 10.7908 13.9507 10.9886C14.0632 11.2865 13.9573 11.52 13.8882 11.6763C13.8766 11.7022 13.8638 11.7306 13.8504 11.7622C13.7188 12.074 13.5588 12.373 13.3725 12.6555C13.0402 13.1525 12.7036 13.5373 12.1416 13.7989C11.853 13.9354 11.5372 14.0045 11.218 14.0011Z" fill="white"/>
          </svg>
          <span class="text-sm hover:text-red-500 hover:cursor-pointer duration-200">+6317-309-2317</span>
        </div>
        <div class="flex flex-row flex-nowrap gap-2 items-center justify-start">
          <svg width="1rem" height="1rem" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.218 14.0011C10.6078 14.0011 9.7508 13.7804 8.46741 13.0634C6.90678 12.1882 5.69966 11.3803 4.14747 9.83213C2.65091 8.33651 1.92264 7.36819 0.903366 5.51343C-0.248119 3.41926 -0.0518294 2.32153 0.16759 1.85237C0.428894 1.29163 0.814598 0.956253 1.31314 0.623373C1.5963 0.437846 1.89597 0.278808 2.20832 0.148276C2.23958 0.134836 2.26865 0.12202 2.29459 0.110456C2.44931 0.0407538 2.68373 -0.0645802 2.98067 0.0479428C3.17883 0.122333 3.35574 0.274552 3.63267 0.548045C4.2006 1.10816 4.9767 2.3556 5.26301 2.96823C5.45523 3.38112 5.58245 3.65368 5.58276 3.95937C5.58276 4.31725 5.40272 4.59325 5.18424 4.89112C5.1433 4.94707 5.10266 5.00052 5.06328 5.0524C4.82542 5.36497 4.77322 5.4553 4.8076 5.61658C4.8773 5.94071 5.3971 6.90559 6.25133 7.75796C7.10557 8.61032 8.04264 9.09729 8.36802 9.16668C8.53618 9.20263 8.62838 9.14824 8.95095 8.90194C8.99721 8.86662 9.04472 8.83005 9.09441 8.79348C9.42761 8.54562 9.69079 8.37027 10.0402 8.37027H10.0421C10.3462 8.37027 10.6066 8.50217 11.0379 8.71972C11.6006 9.00352 12.8855 9.76962 13.4491 10.3382C13.7232 10.6145 13.876 10.7908 13.9507 10.9886C14.0632 11.2865 13.9573 11.52 13.8882 11.6763C13.8766 11.7022 13.8638 11.7306 13.8504 11.7622C13.7188 12.074 13.5588 12.373 13.3725 12.6555C13.0402 13.1525 12.7036 13.5373 12.1416 13.7989C11.853 13.9354 11.5372 14.0045 11.218 14.0011Z" fill="white"/>
          </svg>
          <span class="text-sm hover:text-red-500 hover:cursor-pointer duration-200">+6318-909-0331</span>
        </div>
        <a href="https://www.facebook.com/intelliseven" target="_blank" class="flex flex-row flex-nowrap gap-2 items-center justify-start">
          <svg width="1rem" height="1rem" viewBox="0 0 10 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.49636 20.1385V11.3596H9.45801L9.89824 7.92235H6.49636V5.73297C6.49636 4.74112 6.77271 4.06202 8.19623 4.06202H10V0.997542C9.12236 0.903488 8.2402 0.858074 7.35754 0.861509C4.73972 0.861509 2.94237 2.45962 2.94237 5.39343V7.91592H0V11.3532H2.9488V20.1385H6.49636Z" fill="white"/>
          </svg>
          <span class="text-sm hover:text-red-500 hover:cursor-pointer duration-200">Intelliseven Inc.</span>
        </a>
        <a href="https://www.google.com/search?q=West+City+Plaza%2C+66+West+Ave%2C+Quezon+City%2C+1104+Metro+Manila&rlz=1C1YTUH_enPH1030PH1047&oq=West+City+Plaza%2C+66+West+Ave%2C+Quezon+City%2C+1104+Metro+Manila&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIKCAEQABiABBiiBDIKCAIQABiABBiiBDIKCAMQABiABBiiBDIKCAQQABiABBiiBNIBBzI4NGowajeoAgCwAgA&sourceid=chrome&ie=UTF-8" target="_blank" class="flex flex-row items-start gap-3">
          <svg class="w-fit" width="1rem" height="1rem" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 9.5C6.33696 9.5 5.70107 9.23661 5.23223 8.76777C4.76339 8.29893 4.5 7.66304 4.5 7C4.5 6.33696 4.76339 5.70107 5.23223 5.23223C5.70107 4.76339 6.33696 4.5 7 4.5C7.66304 4.5 8.29893 4.76339 8.76777 5.23223C9.23661 5.70107 9.5 6.33696 9.5 7C9.5 7.3283 9.43534 7.65339 9.3097 7.95671C9.18406 8.26002 8.99991 8.53562 8.76777 8.76777C8.53562 8.99991 8.26002 9.18406 7.95671 9.3097C7.65339 9.43534 7.3283 9.5 7 9.5ZM7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0Z" fill="white"/>
          </svg>
          <div class="w-full text-sm hover:text-red-500 hover:cursor-pointer duration-200">Pent House, West City Plaza, 66 West Ave, Quezon City, Metro Manila</div>
        </a>
      </div>
      <div class="flex flex-col gap-2 lg:items-center">
        <div class="flex flex-col gap-2">
          <span class="font-semibold text-base">Company</span>
          <router-link to="/terms" class="w-full text-sm hover:text-red-500 hover:cursor-pointer duration-200">Terms & Condition</router-link>
          <router-link to="/privacy" class="w-full text-sm hover:text-red-500 hover:cursor-pointer duration-200">Privacy Policy</router-link>
        </div>
      </div>
    </div>
    <div class="flex text-sm justify-center sm:justify-between items-center border-white/60 border-t mx-3 lg:mx-[10vw] mb-3 sm:mb-10 z-10 pt-5 pb-10 text-white">
      <div class="font-thin">LawSys &copy; {{ date.getFullYear() }}. All rights reserved.</div>
      <div class="font-thin hidden sm:block">Powered by <a class="hover:text-secondary duration-200 font-medium" href="https://www.intelliseven.com.ph/" target="_blank" >Intelliseven Technology Solutions Inc.</a></div>
    </div>

    <img class="absolute w-full h-30 -top-52 sm:-top-72 lg:h-[200%] lg:top-[unset] lg:bottom-0 z-0" src="./../../assets/v2/WRAP_footer.png" alt="">

  </div>
</template>

<script setup>
const date = new Date;
const goTo = (id) =>{
  let topPosition = document.getElementById(id).offsetTop - 100;
  if(id == 'overview') topPosition -= 130;
  window.scrollTo({
      top: topPosition,
      left: 0,
      behavior: 'smooth',
  }); 
}

</script>

<style>

</style>